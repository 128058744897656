import React from 'react'
import pink from '@mui/material/colors/pink';
import {GlobalStyles} from '@mui/material';
import typography from '../theme/typography';

const progressBarColor = pink.A400;

const styles = {
      '*': {
         boxSizing: 'border-box',
         margin: 0,
         padding: 0
      },
      html: {
         '-webkit-font-smoothing': 'antialiased',
         '-moz-osx-font-smoothing': 'grayscale',
         height: '100%',
         width: '100%'
      },
      body: {
         backgroundColor: '#f4f6f8',
         height: '100%',
         width: '100%'
      },
      a: {
         textDecoration: 'none'
      },
      '#root': {
         height: '100%',
         width: '100%'
      },
      '#nprogress': {
         position: 'relative',
         zIndex: 999999,
         '& .bar': {
            background: `${progressBarColor} !important`
         }
      },
      '#notistack-snackbar': {
         fontFamily: typography.fontFamily
      }
   }

const globalStyles =  <GlobalStyles  styles={styles} />;
export default globalStyles
