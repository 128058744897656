import {
   AUTHENTICATION_SUCCESS,
   AUTHENTICATION_FAILURE,
   LOGGED_OUT,
   CURRENT_HOST_PDF,
   CURRENT_HOST_PDF_FAILURE
} from '../actions/authenAction';

let isAuth = localStorage.getItem('access-token');
const initialState = { isAuth: Boolean(isAuth), user: null, error: null, hostPdf: null };

export default function authenReducer(state = initialState, action) {
   switch (action.type) {
      case AUTHENTICATION_SUCCESS:
         return {
            ...state,
            isAuth: true,
            user: action.payload.user
         };

      case AUTHENTICATION_FAILURE:
         return {
            ...state,
            isAuth: false,
            user: null,
            error: action.payload.error
         };

      case CURRENT_HOST_PDF:
         return {
            ...state,
            hostPdf: action.payload.host
         };

      case CURRENT_HOST_PDF_FAILURE:
         return {
            ...state,
            hostPdf: null
         };

      case LOGGED_OUT:
         return {
            ...state,
            isAuth: false,
            user: null,
            error: null
         };

      default:
         return state;
   }
}
