/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui'
import {
   Avatar,
   Box,
   Divider,
   Drawer,
   Link,
   List,
   ListItem,
   ListItemText,
   ListItemIcon,
   ListSubheader,
   Typography
} from '@mui/material';
import {
   Monitor as CodeUsageIcon,
   LogOut as LogoutIcon,
} from 'react-feather';
import NProgress from 'nprogress';

import { usePermissions } from 'src/hooks/usePermissions';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { useDialog } from 'src/hooks/dialog';
import { useAuth } from 'src/hooks/useAuth';
import { loggedOut } from 'src/redux/actions/authenAction';

import getInitials from 'src/common/getInitials';
import NavItem from './NavItem';

export const navConfig = [
   // {
   //    subheader: 'Management',
   //    items: [
   //       {
   //          title: 'Users',
   //          icon: UserIcon,
   //          href: '/app/users',
   //          // permission: (can) => can(['EDIT', 'VIEW'], 'USERS')
   //          permission: true
   //       },
   //    ]
   // }
   // {
   //    subheader: '',
   //    items: [
   //       {
   //          title: 'Code usage',
   //          icon: CodeUsageIcon,
   //          href: '/app/code-usage-report',
   //          permission: true
   //       },
   //    ]
   // },
   {
      subheader: '',
      items: [
         {
            title: 'Promotion codes Payment ',
            icon: CodeUsageIcon,
            href: '/app/partner-promotion-codes',
            permission: true
         },
      ]
   }
];

function NavItems({ items, ...rest }) {
   const { can, role } = usePermissions();
   return (
      <List disablePadding>
         {items
            .filter((navItem) =>
               navItem.permission instanceof Function ? navItem.permission(can, role) : navItem.permission
            )
            .reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}
      </List>
   );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
   const key = item.title + depth;

   if (item.items) {
      const open = matchPath(
         {
            path: item.href,
            end: false
         },
         pathname
      );

      acc.push(
         <NavItem depth={depth} icon={item.icon} key={key} info={item.info} open={Boolean(open)} title={item.title}>
            <NavItems depth={depth + 1} pathname={pathname} items={item.items} />
         </NavItem>
      );
   } else {
      acc.push(
         <NavItem depth={depth} href={item.href} icon={item.icon} key={key} info={item.info} title={item.title} />
      );
   }

   return acc;
}

const useStyles = makeStyles()((theme) => ({
   mobileDrawer: {
      width: 256
   },
   desktopDrawer: {
      width: 256,
      top: 64,
      height: 'calc(100% - 64px)'
   },
   avatar: {
      cursor: 'pointer',
      width: '64px',
      height: '64px',
      fontSize: 'unset',
      backgroundColor: ({ avatarColor }) => avatarColor || theme.palette.background.dark
   }
}));

function NavBar({ openMobile, onMobileClose }) {
   const { user } = useSelector((state) => state.authen);
   const { classes } = useStyles({ avatarColor: user?.avatarColor });
   const location = useLocation();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { enqueueSnackbar } = useSnackbar();
   const dialog = useDialog();
   const { logout } = useAuth();

   useEffect(() => {
      if (openMobile && onMobileClose) {
         onMobileClose();
      }
      // eslint-disable-next-line
   }, [location.pathname]);

   const handleLogoutClick = async () => {
      const res = await dialog.confirm('Confirm Logout ? ');
      if (res) {
         NProgress.start();
         try {
            const result = await logout();
            NProgress.done();
            if (result.status === 'logout-success') {
               enqueueSnackbar('Logout success', {
                  variant: 'success',
                  autoHideDuration: 2000
               });
               navigate('/login', { replace: true });
            } else {
               dialog.error('Ops !! some error occurred please contact your system administrator');
            }
         } catch (error) {
            NProgress.done();
            dialog.error(error);
            console.log('something wrong !!', error);
            if (!Boolean(localStorage.getItem('access-token'))) {
               dispatch(loggedOut());
               navigate('/login', { replace: true });
            }
         }
      }
   };

   const content = (
      <Box height="100%" display="flex" flexDirection="column">
         <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box p={2}>
               <Box display="flex" justifyContent="center">
                  <RouterLink to="/app/account">
                     <Avatar alt="User" className={classes.avatar} style={{width:64, height:64, fontSize:'larger'}}>
                        {getInitials(user?.name || '')}
                     </Avatar>
                  </RouterLink>
               </Box>
               <Box mt={2} textAlign="center" whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
                  <Link component={RouterLink} to="/app/account" variant="h5" color="textPrimary" underline="none">
                     {user?.name || ''}
                  </Link>
                  <Typography variant="body2" color="textSecondary">
                     {user?.email || ''}
                  </Typography>
               </Box>
            </Box>
            <Divider />
            <Box p={2}>
               {navConfig.map((config,index) => (
                  <List
                     key={config.subheader + index}
                     subheader={
                        <ListSubheader disableGutters disableSticky>
                           {config.subheader}
                        </ListSubheader>
                     }
                  >
                     <NavItems items={config.items} pathname={location.pathname} />
                  </List>
               ))}
            </Box>
            {/* <Divider />
            <Box p={2}>
               <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
                  <Typography variant="h6" color="textPrimary">
                     Need Help?
                  </Typography>
                  <Link variant="subtitle1" color="secondary" component={RouterLink} to="/docs">
                     Check our docs
                  </Link>
               </Box>
            </Box> */}
            <Divider />
            <Box p={2}>
               <List dense>
                  <ListItem button onClick={handleLogoutClick}>
                     <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                     </ListItemIcon>
                     <ListItemText primary="Log Out" />
                  </ListItem>
               </List>
            </Box>
         </PerfectScrollbar>
      </Box>
   );

   return (
      <>
         <Drawer
            anchor="left"
            classes={{ paper: classes.mobileDrawer }}
            onClose={onMobileClose}
            open={openMobile}
            variant="temporary"
            sx={{
               display:{xs:'block', lg:'none'},
            }}
         >
            {content}
         </Drawer>
         <Drawer
            anchor="left" 
            classes={{ paper: classes.desktopDrawer }} 
            open 
            variant="persistent"
            sx={{
               display:{xs:'none', lg:'block'},
               [`& .MuiDrawer-paper`]: { width: 256, top: 64, boxSizing: 'border-box' },
               }}
         >
            {content}
         </Drawer>
      </>
   );
}

NavBar.propTypes = {
   onMobileClose: PropTypes.func,
   openMobile: PropTypes.bool
};

export default NavBar;
