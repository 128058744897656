import { axiosToken } from 'src/common/AxiosToken';

export function getCurrentUser() {
   return async (dispatch) => {
      const linkCheckAuth = '/self';
      try {
         let json = await axiosToken.get(linkCheckAuth);
         dispatch(authenticationSuccess({ avatarColor: randomColors[rendomNum(10)], ...json.data }));
         localStorage.setItem('userid', json.data.id);
         return { data: 'success', result: json.data };
      } catch (error) {
         localStorage.removeItem('access-token');
         localStorage.removeItem('userid');
         dispatch(authenticationFailure(error));
         throw error;
      }
   };
}

export function setToken(token) {
   return async (dispatch) => {
      try {
         localStorage.setItem('access-token', token);
         return dispatch(getCurrentUser());
      } catch (error) {
         localStorage.removeItem('access-token');
         dispatch(authenticationFailure(error));
         return { data: 'unsuccess', error };
      }
   };
}

export function getHostPdf() {
   return async (dispatch) => {
      try {
         const host = await axiosToken.get('prefix-pdf');
         return dispatch(getCurrentHostPdf(host.data));
      } catch (error) {
         return getCurrentHostPdfFailure(error);
      }
   };
}

function rendomNum(num) {
   return Math.floor(Math.random() * num);
}
const randomColors = [
   // "#4A90E2",
   // "#50E3C2",
   // "#F5A623",
   // "#9013FE",
   // "#D0021B",
   // "#F8E71C",
   // "#7ED321",
   // "#9B9B9B",
   // "#BD10E0",
   // "#8B572A",

   // "#55efc4",
   // "#ffeaa7",
   // "#81ecec",
   // "#fab1a0",
   // "#74b9ff",
   // "#ff7675",
   // "#b8e994",
   // "#a29bfe",
   // "#fd79a8",
   // "#b2bec3",

   '#698396',
   '#A9C8C0',
   '#DBBC8E',
   '#AE8A8C',
   '#7C98AB',
   '#F5BFD2',
   '#E5DB9C',
   '#D0BCAC',
   '#BEB4C5',
   '#E6A57E'
];

// // Handle HTTP errors since fetch won't.
// function handleErrors(response) {
//     if (!response.ok) {
//         throw Error(response.statusText);
//     }
//     return response;
// }

export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE';
export const CURRENT_HOST_PDF = 'CURRENT_HOST_PDF';
export const CURRENT_HOST_PDF_FAILURE = 'CURRENT_HOST_PDF_FAILURE';
export const LOGGED_OUT = 'LOGGED_OUT';

export const authenticationSuccess = (user) => ({
   type: AUTHENTICATION_SUCCESS,
   payload: { user }
});

export const authenticationFailure = (error) => ({
   type: AUTHENTICATION_FAILURE,
   payload: { error }
});

export const getCurrentHostPdf = (host) => ({
   type: CURRENT_HOST_PDF,
   payload: host
});

export const getCurrentHostPdfFailure = (error) => ({
   type: CURRENT_HOST_PDF_FAILURE,
   payload: { error }
});

export const loggedOut = () => ({
   type: LOGGED_OUT
});
