import React from 'react';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const BaseDialog = ({ open, title, content, action, dialogProps }) => {
   const handleClose = (e, reason) => {
      return !(reason === 'backdropClick');
   };

   return (
      <Dialog fullWidth onClose={handleClose} open={open} {...dialogProps}>
         {title && <DialogTitle disabletypography>{title}</DialogTitle>}

         <DialogContent>{content}</DialogContent>

         <DialogActions>{action}</DialogActions>
      </Dialog>
   );
};

export default BaseDialog;
