import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { matchRoutes, Outlet, useLocation, useMatch, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui'
import NavBar, { navConfig } from './components/NavBar';
import TopBar from './components/TopBar';
import axiosToken from 'src/common/AxiosToken';
import { allRoutes } from 'src/Routes';

const useStyles = makeStyles()((theme) => ({
   root: {
      backgroundColor: theme.palette.background.dark,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
   },
   wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: 64,
      [theme.breakpoints.up('lg')]: {
         paddingLeft: 256
      }
   },
   contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden'
   },
   content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto',
      scrollBehavior: 'smooth'
   }
}));

const flatItems = (navItems) => {
   if (!navItems) return [];
   if (navItems.some((nav) => nav.items && Array.isArray(nav.items))) {
      const flatedItems = navItems.flatMap((page) => page.items || page);
      return flatItems(flatedItems);
   }
   return navItems;
};

const AuthenLayout = () => {
   const { classes } = useStyles();
   const params = useParams();

   const [isMobileNavOpen, setMobileNavOpen] = useState(false);
   const location = useLocation();

   const allBackofficePages = useMemo(() => flatItems(navConfig), []);

   const addLog = useCallback(async (page, info) => {
      const linkAddLog = '/user-log';
      try {
         await axiosToken.post(linkAddLog, { page, info });
      } catch (error) {
         console.log('error', error);
      }
   }, []);

   useEffect(() => {
      let navItem = allBackofficePages.find((navItem) => navItem.href === location.pathname);
      if (!navItem) {
         const [match] = matchRoutes(allRoutes, location);
         navItem = match.route;
      }
      const pageName = navItem?.title || navItem?.name?.replace(/([a-z])([A-Z])/g, '$1 $2') || location.pathname;
      const info = {
         url: location.pathname,
         ...params
      };
      addLog(pageName, info);
   }, [location.pathname, addLog, allBackofficePages]);

   return (
      <div className={classes.root}>
         <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
         <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
         <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
               <div className={classes.content} id="scrolling-container">
                  <Outlet />
               </div>
            </div>
         </div>
      </div>
   );
};

export default AuthenLayout;
