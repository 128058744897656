import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Widgets from '@mui/icons-material/Widgets';
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
   poweredLink: {
      color: theme.palette.primary.main,
      textDecoration: 'none'
   },
   whiteColor: {
      color: '#fff',
      textDecoration: 'none'
   }
}));

const NomalBrand = () => {
   const { classes } = useStyles();
   return (
      <Box alignItems={'center'} display="flex" justifyContent="center" p={1}>
         <Box color={'#007bff'} fontSize={64} lineHeight={0}>
            <Widgets fontSize={'medium'} className={classes.whiteColor} />
         </Box>
         <Box p={1}>
            <Typography variant={'h6'} className={classes.whiteColor}>
               ENABLE SURVEY - Partner
            </Typography>
         </Box>
      </Box>
   );
};

export default NomalBrand;
