import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import InputIcon from '@mui/icons-material/Input';
import NProgress from 'nprogress';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { useDialog } from 'src/hooks/dialog';
import Logo from 'src/components/logo/NormalLogo';
import { useAuth } from 'src/hooks/useAuth';
import { loggedOut } from 'src/redux/actions/authenAction';

const useStyles = makeStyles()(() => ({
   root: {},
   avatar: {
      width: 60,
      height: 60
   }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
   const { classes, cx } = useStyles();
   console.log('classes', classes)
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { enqueueSnackbar } = useSnackbar();
   const dialog = useDialog();
   const { logout } = useAuth();

   const handleLogoutClick = async () => {
      
      const res = await dialog.confirm('Confirm Logout ? ');
      if (res) {
         NProgress.start();
         try {
            const result = await logout();
            NProgress.done();
            if (result.status === 'logout-success') {
               enqueueSnackbar('Logout success', {
                  variant: 'success',
                  autoHideDuration: 2000
               });
               navigate('/login', { replace: true });
            } else {
               dialog.error('Ops !! some error occurred please contact your system administrator');
            }
         } catch (error) {
            NProgress.done();
            dialog.error(error);
            console.log('something wrong !!', error);
            if (!Boolean(localStorage.getItem('access-token'))) {
               dispatch(loggedOut());
               navigate('/login', { replace: true });
            }
         }
      }
   };
   return (
      <AppBar className={cx(classes.root, className)} elevation={0} {...rest}>
         <Toolbar>
            <RouterLink to="/">
               <Logo />
            </RouterLink>
            <Box flexGrow={1} />
            <Hidden mdDown>
               <IconButton color="inherit" onClick={handleLogoutClick}>
                  <InputIcon />
               </IconButton>
            </Hidden>
            <Hidden lgUp>
               <IconButton color="inherit" onClick={onMobileNavOpen}>
                  <MenuIcon />
               </IconButton>
            </Hidden>
         </Toolbar>
      </AppBar>
   );
};

TopBar.propTypes = {
   className: PropTypes.string,
   onMobileNavOpen: PropTypes.func
};

export default TopBar;
