import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { makeStyles } from 'tss-react/mui'
import { Box } from '@mui/material';
import SquareSpinner from './SquareSpinner';

const useStyles = makeStyles()((theme) => ({
   root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      minHeight: '100%',
      padding: theme.spacing(3)
   }
}));

function LoadingScreen() {
   const { classes } = useStyles();

   useEffect(() => {
      NProgress.start();
      return () => {
         NProgress.done();
      };
   }, []);

   return (
      <div className={classes.root}>
         <Box>
            <SquareSpinner />
         </Box>
      </div>
   );
}

export default LoadingScreen;
