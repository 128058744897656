import * as React from 'react';
// import { useTranslation } from 'react-i18next';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BaseDialog from 'src/components/BaseDialog';

const isReactComponent = (opts) => {
   return typeof opts === 'object' && opts.hasOwnProperty('message') && React.isValidElement(opts.message);
};

const alert = (opts, ref) => {
   console.log(ref);
   const defaultTitle = '';
   const defaultOkButtonText = 'ok';

   let title, message, okButtonText;

   if (React.isValidElement(opts)) {
      title = '';
      okButtonText = 'ok';
      message = opts;
   } else if (isReactComponent(opts)) {
      title = opts.title || defaultTitle;
      okButtonText = opts.okButtonText || defaultOkButtonText;
      message = opts.message;
   } else {
      if (typeof opts === 'string') {
         if (/\.\*$/.test(opts)) {
            const prefix = opts.replace(/\*$/, '');
            title = prefix + 'title';
            message = prefix + 'message';
            okButtonText = prefix + 'ok-button';
         } else {
            message = opts;
         }
      } else if (typeof opts === 'object' && opts.hasOwnProperty('message')) {
         title = opts.title;
         message = opts.message;
         okButtonText = opts.okButtonText;
      } else {
         message = opts;
      }

      title = title || defaultTitle;
      okButtonText = okButtonText || defaultOkButtonText;
      message = <DialogContentText>{'' + message}</DialogContentText>;
   }

   ref.setDialogState({
      title: <Typography variant="h4">{title}</Typography>,
      content: message,
      action: (
         <>
            <Button variant="contained" color="primary" onClick={(e) => ref.handleClick(e, true)}>
               {okButtonText}
            </Button>
         </>
      ),
      dialogProps: {
         onClose: (e, reason) => ref.handleClick(e, true)
      }
   });

   return ref.deferred();
};

const confirm = (opts, ref) => {
   const defaultTitle = 'Confirmation';
   const defaultOkButtonText = 'ok';
   const defaultCancelButtonText = 'cancel';

   let title, message, okButtonText, cancelButtonText;

   if (React.isValidElement(opts)) {
      title = defaultTitle;
      okButtonText = defaultOkButtonText;
      cancelButtonText = defaultCancelButtonText;
      message = opts;
   } else if (isReactComponent(opts)) {
      title = opts.title || defaultTitle;
      okButtonText = opts.okButtonText || defaultOkButtonText;
      cancelButtonText = opts.cancelButtonText || defaultCancelButtonText;
      message = opts.message;
   } else {
      if (typeof opts === 'string') {
         if (/\.\*$/.test(opts)) {
            const prefix = opts.replace(/\*$/, '');
            title = prefix + 'title';
            message = prefix + 'message';
            okButtonText = prefix + 'ok-button';
            cancelButtonText = prefix + 'cancel-button';
         } else {
            message = opts;
         }
      } else if (typeof opts === 'object' && opts.hasOwnProperty('message')) {
         title = opts.title;
         message = opts.message;
         okButtonText = opts.okButtonText;
         cancelButtonText = opts.cancelButtonText;
      } else {
         message = opts;
      }

      title = title || defaultTitle;
      okButtonText = okButtonText || defaultOkButtonText;
      cancelButtonText = cancelButtonText || defaultCancelButtonText;
      message = <DialogContentText>{'' + message}</DialogContentText>;
   }

   ref.setDialogState({
      title: <Typography variant="h4">{title}</Typography>,
      content: message,
      action: (
         <>
            <Button variant="outlined" color="primary" onClick={(e) => ref.handleClick(e, false)} autoFocus>
               {cancelButtonText}
            </Button>
            <Button variant="contained" color="primary" onClick={(e) => ref.handleClick(e, true)}>
               {okButtonText}
            </Button>
         </>
      ),
      dialogProps: {
         onClose: (e, reason) => ref.handleClick(e, false)
      }
   });

   return ref.deferred();
};

const errorCode = {
   '413': 'ไฟล์มีขนาดใหญ่เกินไป กรุณาลดขนาดและลองใหม่อีกครั้ง'
}

const error = (opts, ref) => {
   const defaultTitle = 'Something wrong !';
   const defaultOkButtonText = 'ok';

   if (opts instanceof Error) {
      console.log('dialog-err : if opts instanceof Error', opts);
      let title, message, okButtonText;

      if (opts.response) {
         const { headers, statusText, status, data } = opts.response;
         let messageContent = <DialogContentText>{errorCode[status] || data.message || statusText}</DialogContentText>;

         const traceContent = headers['__trace_id'] && (
            <DialogContentText>ERROR CODE: {headers['__trace_id']}</DialogContentText>
         );

         message = (
            <>
               {messageContent}
               {traceContent}
            </>
         );
      } else {
         console.log('dialog-err : else response', opts);
         message = <DialogContentText>{'' + opts.message}</DialogContentText>;
      }

      title = defaultTitle;
      okButtonText = defaultOkButtonText;

      ref.setDialogState({
         title: (
            <Box display="flex" alignItems="center">
               <Box mr={1}>
                  <ErrorOutlineIcon fontSize="large" />
               </Box>
               <Typography variant="h4">{title}</Typography>
            </Box>
         ),
         content: message,
         action: (
            <>
               <Button variant="contained" color="primary" onClick={(e) => ref.handleClick(e, true)}>
                  {okButtonText}
               </Button>
            </>
         ),
         dialogProps: {
            onClose: (e, reason) => ref.handleClick(e, true)
         }
      });
   } else {
      console.log('dialog-err : else opts instanceof Error', opts);
      let title, message, okButtonText;

      if (React.isValidElement(opts)) {
         console.log('dialog-err : React.isValidElement');
         title = defaultTitle;
         okButtonText = defaultOkButtonText;
         message = opts;
      } else if (isReactComponent(opts)) {
         console.log('dialog-err : isReactComponent');
         title = opts.title || defaultTitle;
         okButtonText = opts.okButtonText || defaultOkButtonText;
         message = opts.message;
      } else {
         console.log('dialog-err : else');
         if (typeof opts === 'string') {
            console.log('dialog-err : opts=string');
            if (/\.\*$/.test(opts)) {
               const prefix = opts.replace(/\*$/, '');
               title = prefix + 'title';
               message = prefix + 'message';
               okButtonText = prefix + 'ok-button';
            } else {
               message = opts;
            }
         } else if (typeof opts === 'object' && opts.hasOwnProperty('message')) {
            console.log('dialog-err : opts=object');
            title = opts.title;
            message = opts.message;
            okButtonText = opts.okButtonText;

            Object.keys(errorCode).forEach(key => {
               if ((opts.text ?? '').includes(key)) {
                  message = errorCode[key];
               }
            })
         } else {
            console.log('dialog-err : opts=else');
            message = opts;
         }

         title = title || defaultTitle;
         okButtonText = okButtonText || defaultOkButtonText;
         message = <DialogContentText>{'' + message}</DialogContentText>;
      }

      ref.setDialogState({
         title: (
            <Box display="flex" alignItems="center">
               <Box mr={1} mt={0.5}>
                  <ErrorOutlineIcon fontSize="large" />
               </Box>
               <Typography variant="h4">{title}</Typography>
            </Box>
         ),
         content: message,
         action: (
            <>
               <Button variant="contained" color="primary" onClick={(e) => ref.handleClick(e, true)}>
                  {okButtonText}
               </Button>
            </>
         ),
         dialogProps: {
            onClose: (e, reason) => ref.handleClick(e, true)
         }
      });
   }

   return ref.deferred();
};

const DialogServiceContext = React.createContext({
   alert: () => Promise.resolve(void 0),
   confirm: () => Promise.resolve(void 0),
   error: () => Promise.resolve(void 0)
});

export const useDialog = () => React.useContext(DialogServiceContext);

// export const withDialog = (component) => {

//    const withDialogHOC = props => {

//      const additionalProps = {
//         dialog: useDialog(),
//      };

//      return React.createElement(component, { ...props, ...additionalProps })
//    };

//    withDialogHOC.displayName = "withDialog";

//    return withDialogHOC;
// }

export const DialogProvider = ({ children }) => {
   // const [ t ] = useTranslation();

   const [dialogState, setDialogState] = React.useState(null);

   const awaitingPromiseRef = React.useRef({
      resolve: () => void 0,
      reject: () => void 0
   });

   const deferred = () =>
      new Promise((resolve, reject) => {
         awaitingPromiseRef.current = { resolve, reject };
      });

   const handleClick = (e, value) => {
      if (awaitingPromiseRef.current) {
         awaitingPromiseRef.current.resolve(value);
      }

      setDialogState(null);
   };

   const ref = {
      setDialogState,
      handleClick,
      deferred
      // t,
   };

   const dialog = {
      // ALERT
      // - { title, message, okButtonText } #message -> string | components
      // - message
      // - component
      alert: (opts) => alert(opts, ref),
      // CONFIRM
      // - { title, message, okButtonText, cancelButtonText } #message -> string | components
      // - message
      // - component
      confirm: (opts) => confirm(opts, ref),
      // ERROR
      // - { title, message, okButtonText } #message -> string | components
      // - message
      // - component
      // - http error
      // - error
      error: (opts) => error(opts, ref)
   };

   return (
      <>
         <DialogServiceContext.Provider value={dialog} children={children} />

         <BaseDialog open={Boolean(dialogState)} {...dialogState} />
      </>
   );
};
