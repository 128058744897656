import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import moment from 'moment';

const generateId = (prefix = "", inxValue = 0) => {
   return prefix + "-" +  moment().add(inxValue, 'ms').valueOf();
}

const BasedSnackbar = (props) => {
    const {anchorOrigin, open, autoHideDuration, close, snackbarKey, severity,message, direction} = props
    return (
         <Snackbar
            sx={{
               '&.MuiSnackbar-anchorOriginBottomRight': {
                  bottom: 24,
               },
            }}
            anchorOrigin={anchorOrigin}
            open={open}
            autoHideDuration={autoHideDuration ?? 3000}
            onClose={close}
            TransitionComponent={(transitionProps) => <Slide {...transitionProps} direction={direction || 'left'} />}
            key={snackbarKey}
      >
            <MuiAlert
               sx={{
                  minWidth: 400,
               }}
               elevation={6}
               variant="filled"
               onClose={close}
               severity={severity}
            >
               {message}
            </MuiAlert>
         </Snackbar>
    );
};

const doSomething = (...params) => console.log('params', params)

const SnackbarServiceContext = createContext({
   enqueueSnackbar: (content, opt) =>  { doSomething(content, opt); return { key: '', closeSnackbar: () => {} }},
   info: (content, opt) => { doSomething(content, opt); return { key: '', closeSnackbar: () => {} }},
   warning: (content, opt) => { doSomething(content, opt); return { key: '', closeSnackbar: () => {} }},
   error: (content, opt) => { doSomething(content, opt); return { key: '', closeSnackbar: () => {} }},
   success: (content, opt) => { doSomething(content, opt); return { key: '', closeSnackbar: () => {} }},
});

export const useSnackbar = () => useContext(SnackbarServiceContext);

const initialSnackbarState = {
   anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
   open: false,
   autoHideDuration: 3000,
   severity: 'info',
   message: '',
   direction: 'right',
};

export const SnackbarProvider = ({ children }) => {
   const [snackbarState, setSnackbarState] = useState(initialSnackbarState);
   const close = useCallback(() => {
      setSnackbarState((prev) => ({
         ...prev,
         open: false,
      }));
   }, []);
   const handleShowSnackbar = useCallback(
      (severity, content, opt) => {
         const snackbarKey = generateId('snack');
         let snackbarMessage = '';
         if (content instanceof Error) {
            if (content.response) {
               const { statusText, data } = content.response;
               snackbarMessage = data.code
                  ? data.message || 'ERROR-UNKNOWN'
                  : statusText;
            } else {
               snackbarMessage = content.message ? content.message : 'ERROR-UNKNOWN'
            }
         } else {
            snackbarMessage = content.toString();
         }
         setSnackbarState({
            ...initialSnackbarState,
            ...opt,
            open: true,
            message: snackbarMessage,
            severity: severity,
            snackbarKey: snackbarKey,
         });
         return { key: snackbarKey, closeSnackbar: close };
      },
      [close],
   );

   const value = useMemo(() => {
      return {
        enqueueSnackbar: (content, opt) => {
            const {variant, ...restOpt} = opt
            handleShowSnackbar(variant || 'info', content , restOpt)
            
        },
         info: (...params) => handleShowSnackbar('info', ...params),
         warning: (...params) => handleShowSnackbar('warning', ...params),
         error: (...params) => handleShowSnackbar('error', ...params),
         success: (...params) => handleShowSnackbar('success', ...params),
      };
   }, [handleShowSnackbar]);
   return (
      <>
         <SnackbarServiceContext.Provider value={value}>{children}</SnackbarServiceContext.Provider>
         <BasedSnackbar {...snackbarState} close={close} />
      </>
   );
};

// how to use
// const snackbar = useSnackbar();
// snackbar.success("message", options)

// default options = {
//    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
//    open: false,
//    autoHideDuration: 3000,
//    severity: 'info',
//    message: '',
//    direction: 'left',
// };
