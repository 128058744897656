import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { DialogProvider } from 'src/hooks/dialog';
import moment from 'moment-timezone';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import globalStyles from 'src/components/GlobalStyles';

import 'src/common/chartjs';
import theme from 'src/theme';
import Routes from 'src/Routes';

import store from 'src/redux/store';
import { PermissionsProvider } from './hooks/usePermissions';
import ErrorBoundary from './views/errors/errorBoundary/ErrorBoundaryView';
import { SnackbarProvider as MuiSnackbarProvider } from './hooks/useSnackbar';

const App = () => {
   return (
      <StyledEngineProvider injectFirst>
         <ThemeProvider theme={theme}>
            <ErrorBoundary>
               <Provider store={store}>
                  <PermissionsProvider store={store}>
                     <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment.tz.setDefault('Asia/Bangkok')}>
                        <DialogProvider>
                           {/* <SnackbarProvider maxSnack={3} > */}
                              <MuiSnackbarProvider>
                                 {globalStyles}
                                 <Routes />
                              </MuiSnackbarProvider>
                           {/* </SnackbarProvider> */}
                        </DialogProvider>
                     </LocalizationProvider>
                  </PermissionsProvider>
               </Provider>
            </ErrorBoundary>
         </ThemeProvider>
      </StyledEngineProvider>
   );
};

export default App;
