import React, { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { PublicRoute, PrivateRoute } from 'src/components/helper';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import AuthenLayout from 'src/layouts/authenLayout/AuthenLayout';
import UnAuthenLayout from 'src/layouts/unAuthenLayout/UnAuthenLayout';

const UserAccount = lazy(() => import('src/views/account/userAccount/UserAccount'));
const CodeUsageReport = lazy(() => import('src/views/codeUsageReport/CodeUsageReport'));
const PartnerPromotionCode = lazy(() => import('src/views/partnerPromotionCode/PartnerPromotionCodes'));
const PromotionCodeDetails = lazy(() => import('src/views/partnerPromotionCode/PromotionCodeDetails'));

const LoginView = lazy(() => import('src/views/auth/login/Login'));
const ForgotPassword = lazy(() => import('src/views/auth/forgotPassword/ForgotPassword'));
const ChangeFirstPassword = lazy(() => import('src/views/auth/changeFirstPassword/ChangeFirstPassword'));
const ResetPassword = lazy(() => import('src/views/auth/resetPassword/ResetPassword'));
const LoadingView = lazy(() => import('src/components/loading/LoadingScreen'));
const NotFoundView = lazy(() => import('src/views/errors/pageNotFound/NotFoundView'));

const pfx = '/app';
// prettier-ignore
const routesConfig = [
   {
      path: pfx,
      element: <PrivateRoute component={AuthenLayout} />,
      children: [
         { path: `${pfx}/`, element: <Navigate to={`${pfx}/account`} /> },
         { path: `${pfx}/account`, name: 'UserAccount', element: <UserAccount /> },
         { path: `${pfx}/code-usage-report`, name: 'CodeUsage', element: <CodeUsageReport /> },
         { path: `${pfx}/partner-promotion-codes`, name: 'PartnerPromotionCode', element: <PartnerPromotionCode /> },
         {
            path: `${pfx}/promotion-codes-details`,
            name: 'PromotionCodeDetails',
            element: <PromotionCodeDetails />
         },
         { path: `${pfx}/loading`, name: 'LoadingView', element: <LoadingView /> },
         { path: `${pfx}/*`, name: 'NotFoundView', element: <NotFoundView /> }
      ]
   },
   {
      path: '/',
      element: <UnAuthenLayout />,
      children: [
         { path: '/', element: <Navigate to="/login" /> },
         { path: 'login', element: <PublicRoute component={LoginView} /> },
         { path: 'forgot-password', element: <PublicRoute component={ForgotPassword} /> },
         { path: '404', element: <NotFoundView /> },
         { path: '*', element: <NotFoundView /> },
         { path: '404', element: <NotFoundView /> },
         { path: 'reset-password', element: <ResetPassword /> },

         { path: 'change-password', element: <PrivateRoute component={ChangeFirstPassword} /> }
      ]
   }
];

export const allRoutes = routesConfig.flatMap((route) => route.children).map(({ element, ...rest }) => rest);

function Routes() {
   const routing = useRoutes(routesConfig);
   return <Suspense fallback={<LoadingScreen />}>{routing}</Suspense>;
}

export default Routes;
