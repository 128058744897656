import axiosToken from 'src/common/AxiosToken';
import { getOrderById } from './customerOrderAction';
import { v4 as uuid } from 'uuid';

function getInitPackageDiscount(discount, discount_cal_type) {
   if (discount && !isNaN(Number(discount)) && Number(discount) > 0) {
      return [
         {
            discount: Number(discount),
            discount_cal_type: discount_cal_type,
            disabled: true
         }
      ];
   }
   return [];
}

export const startQuotation = (quotationData, can) => async (dispatch, getState) => {
   const packages = getState().customerOrder.packages;
   const order = getState().customerOrder.order;
   const unuseDays = getState().customerOrder.unuseDays;
   const defaultAdditionalTaxRate = getState().customerOrder.defaultAdditionalTaxRate;
   const defaultTeamRole = getState().customerOrder.defaultTeamRole;

   console.log('quotationData', quotationData);

   const listPackages = [
      { isListSubheader: true, id: 9998, title: 'Individual Packages' },
      ...packages.filter((pk) => {
         if (pk.package_type === 'individual') {
            if (['normal', 'private'].includes(pk.package_order_type)) {
               return true;
            }
            if (
               pk.package_order_type === 'staff' &&
               order.order_type === 'new' &&
               can(['SELL_STAFF'], 'ORDER_QUOTATION')
            ) {
               return true;
            }
            if (
               pk.package_order_type === 'free' &&
               order.order_type === 'new' &&
               can(['SELL_FREE'], 'ORDER_QUOTATION')
            ) {
               return true;
            }
         }
         return false;
      }),
      { isListSubheader: true, id: 9999, title: 'Team Packages' },
      ...packages.filter((pk) => {
         if (pk.package_type === 'team') {
            if (['normal', 'private'].includes(pk.package_order_type)) {
               return true;
            }
            if (
               pk.package_order_type === 'staff' &&
               order.order_type === 'new' &&
               can(['SELL_STAFF'], 'ORDER_QUOTATION')
            ) {
               return true;
            }
            if (
               pk.package_order_type === 'free' &&
               order.order_type === 'new' &&
               can(['SELL_FREE'], 'ORDER_QUOTATION')
            ) {
               return true;
            }
         }
         return false;
      })
   ];

   let dataToStart = {
      quotationId: order.quotation?.id,
      listPackages: listPackages,
      quotationSetWithholdingTax: Number(defaultAdditionalTaxRate),
      quotationSetUnuseDays: unuseDays,
      quotationSetRemain: order.quotation?.package_remain_price || 0,
      defaultTeamRole: defaultTeamRole
   };

   if (quotationData) {
      const selectedPackageById = packages.find((pk) => pk.id === quotationData.package.package_id);
      const {
         addon_features,
         remain_addon_features,
         discount: packageDiscount,
         discount_cal_type,
         ...selectedPackage
      } = selectedPackageById;
      const isExistsInitPackageDiscount = !isNaN(Number(packageDiscount)) && Number(packageDiscount) > 0;
      dataToStart = {
         quotationId: order.quotation?.id,
         quotationSetId: quotationData?.id || '',
         listPackages: listPackages,
         listAddons: addon_features.map((feature) => ({ ...feature, default_price: feature.default_price })),
         addedAddons: quotationData.addons.map(({ discount, detail, is_package_feature, ...addon }) => {
            let feature = addon_features.find((ft) => ft.code === addon.feature_code);

            return {
               ...feature,
               ...addon,
               id: uuid(),
               addonDiscount: discount.map((dc) => ({ ...dc, discount: Number(dc.discount) })),
               price_per_unit: !isNaN(Number(addon.price)) ? Number(addon.price) : 0,
               price: addon.total_price,
               default_price: detail.price,
               isPackageFeature: !!is_package_feature
            };
         }),
         selectedPackage: {
            ...selectedPackage,
            package_feature: [
               ...selectedPackage.package_feature,
               ...remain_addon_features.map(({ detail, remain, ...rest }) => ({
                  ...rest,
                  feature_detail: detail,
                  quantity: remain
               }))
            ]
         },
         seats: quotationData.package.seats,
         packageDiscount: (quotationData.package.discount || []).map((dc, disIndex) => ({
            ...dc,
            discount: Number(dc.discount),
            disabled: disIndex === 0 && isExistsInitPackageDiscount
         })),
         customizePakageName: quotationData.package.package_name,
         quotationSetDiscount: (quotationData.discount || []).map((dc) => ({ ...dc, discount: Number(dc.discount) })),
         quotationSetWithholdingTax: !isNaN(Number(quotationData.additional_tax_rate))
            ? Number(quotationData.additional_tax_rate)
            : Number(defaultAdditionalTaxRate),
         quotationSetUnuseDays: unuseDays,
         quotationSetRemain: order.quotation?.package_remain_price || 0,
         note: quotationData.note
      };
   }
   console.log('dataToStart', dataToStart);
   dispatch(begin(dataToStart));
};

export const selectPackage = (packageId) => async (dispatch, getState) => {
   if (!packageId) {
      return;
   }
   const packages = getState().quotation.listPackages;
   const addedAddons = getState().quotation.addedAddons;
   const seats = getState().quotation.seats;
   const selectedPackageById = packages.find((pk) => pk.id === packageId);
   if (!selectedPackageById) return;

   const {
      addon_features,
      remain_addon_features,
      default_addon_features,
      discount,
      discount_cal_type,
      ...selectedPackage
   } = selectedPackageById;

   const updatedQuotation = {
      selectedPackage: {
         ...selectedPackage,
         package_feature: [
            ...selectedPackage.package_feature,
            ...remain_addon_features.map(({ detail, remain, ...rest }) => ({
               ...rest,
               feature_detail: detail,
               quantity: remain
            }))
         ]
      },
      packageDiscount: getInitPackageDiscount(discount, discount_cal_type),
      seats:
         selectedPackageById.package_type === 'individual'
            ? 1
            : Number(seats) < selectedPackageById.minimum_seats
               ? selectedPackageById.minimum_seats
               : Number(seats),
      customizePakageName: selectedPackageById.name,
      listAddons: addon_features.map((feature) => ({ ...feature, default_price: feature.price })),
      addedAddons: [
         ...default_addon_features
            .filter(addon => (selectedPackage.package_type === 'team' || addon.feature_type !== 'team-role'))
            .map(({ detail, is_package_feature, ...ft }) => ({
               ...detail,
               ...ft,
               id: uuid(),
               isPackageFeature: !!is_package_feature,
               default_price: Number(ft.default_price),
               price_per_unit: !isNaN(Number(ft.price)) ? Number(ft.price) : Number(detail.price) || 0,
               price: ft.total_price,
               addonDiscount: []
            })),
         ...addedAddons
            .filter((addon) => addon_features.some((af) => af.code === addon.code) && (selectedPackage.package_type === 'team' || addon.feature_type !== 'team-role'))
            .filter((addon) => default_addon_features.every((af) => af.detail?.code !== addon.code))
            .map((addon) => ({ ...addon, id: uuid() }))
      ]
   };
   // ...addonToAdd,
   // default_price: addonToAdd.price,
   // price_per_unit: !isNaN(Number(addonToAdd.price)) ? Number(addonToAdd.price) : undefined,
   // addonDiscount: []
   console.log('selectedPackageById', selectedPackageById);
   console.log('updatedQuotation', updatedQuotation);
   dispatch(setSelectPackage(updatedQuotation));
};

export const onQuotationChangeWithKey = (key, value) => async (dispatch, getState) => {
   const tagetKeyValue = getState().quotation[key];
   console.log('tagetKeyValue', tagetKeyValue);
   const finalValue = value instanceof Function ? value(tagetKeyValue) : value;
   return dispatch(updateWithKey(key, finalValue));
};
export const changePackageDiscount = (updatedDiscountData) => async (dispatch, getState) => {
   const packageDiscount = getState().quotation.packageDiscount;
   const index = packageDiscount.findIndex((pkdisc) => pkdisc.id === updatedDiscountData.id);
   if (index === -1) {
      return;
   }
   return dispatch(updatePackageDiscount(updatedDiscountData, index));
};
export const changeQuotatinSetDiscount = (updatedDiscountData) => async (dispatch, getState) => {
   const quotationSetDiscount = getState().quotation.quotationSetDiscount;
   const index = quotationSetDiscount.findIndex((setDiscount) => setDiscount.id === updatedDiscountData.id);
   if (index === -1) {
      return;
   }
   return dispatch(updateQuotationSetDiscount(updatedDiscountData, index));
};

export const changeAddonDiscount = (updatedDiscountData, addonId) => async (dispatch, getState) => {
   const addedAddons = getState().quotation.addedAddons;
   const addedAddonsIndex = addedAddons.findIndex((addon) => addon.id === addonId);
   if (addedAddonsIndex === -1) {
      return;
   }
   let updatedAddondata = addedAddons[addedAddonsIndex];
   const discountIndex = updatedAddondata.addonDiscount.findIndex((disc) => disc.id === updatedDiscountData.id);
   updatedAddondata = {
      ...updatedAddondata,
      addonDiscount: [
         ...updatedAddondata.addonDiscount.slice(0, discountIndex),
         updatedDiscountData,
         ...updatedAddondata.addonDiscount.slice(discountIndex + 1)
      ]
   };
   return dispatch(updateAddedAddon(updatedAddondata, addedAddonsIndex));
};

export const addAddonDiscount = (newDiscountData, addonId) => async (dispatch, getState) => {
   const addedAddons = getState().quotation.addedAddons;
   const addedAddonsIndex = addedAddons.findIndex((addon) => addon.id === addonId);
   if (addedAddonsIndex === -1) {
      return;
   }
   let updatedAddondata = addedAddons[addedAddonsIndex];
   updatedAddondata = {
      ...updatedAddondata,
      addonDiscount: [...updatedAddondata.addonDiscount, newDiscountData]
   };
   console.log('updatedAddondata', updatedAddondata);
   console.log('addedAddonsIndex', addedAddonsIndex);
   return dispatch(updateAddedAddon(updatedAddondata, addedAddonsIndex));
};
export const removeAddonDiscount = (discountId, addonId) => async (dispatch, getState) => {
   const addedAddons = getState().quotation.addedAddons;
   const addedAddonsIndex = addedAddons.findIndex((addon) => addon.id === addonId);
   if (addedAddonsIndex === -1) {
      return;
   }
   let updatedAddondata = addedAddons[addedAddonsIndex];
   updatedAddondata = {
      ...updatedAddondata,
      addonDiscount: updatedAddondata.addonDiscount.filter((discount) => discount.id !== discountId)
   };
   return dispatch(updateAddedAddon(updatedAddondata, addedAddonsIndex));
};
export const changeAddedAddons = (id, key, value) => async (dispatch, getState) => {
   const addedAddons = getState().quotation.addedAddons;
   const index = addedAddons.findIndex((addon) => addon.id === id);
   const targetAddon = addedAddons.find((addon) => addon.id === id);
   if (index === -1) {
      return;
   }
   let updatedTargetAddon = {
      ...targetAddon,
      [key]: value !== '' && !isNaN(Number(value)) ? Number(value) : ''
   };
   return dispatch(updateAddedAddon(updatedTargetAddon, index));
};

export const addPackageAddon = (code) => async (dispatch, getState) => {
   const listAddons = getState().quotation.listAddons;
   const addonToAdd = listAddons.find((listAddon) => listAddon.code === code);
   if (!addonToAdd) {
      return;
   }
   dispatch(
      addMoreAddon({
         ...addonToAdd,
         id: uuid(),
         default_price: addonToAdd.price,
         price_per_unit: !isNaN(Number(addonToAdd.price)) ? Number(addonToAdd.price) : undefined,
         addonDiscount: []
      })
   );
};

export const validateBeforeSaveQuotationSet = () => async (dispatch, getState) => {
   // const quotation = getState().quotation;
};
export const saveQuotationSet =
   ({ onSuccess = () => { }, onError = () => { } }) =>
      async (dispatch, getState) => {
         const quotation = getState().quotation;
         const order = getState().customerOrder.order;
         dispatch(displayQuotationLoading(true));
         const dataToSave = {
            quotation_id: quotation.quotationId,
            quotation_set_id: quotation.quotationSetId || null,
            quotationSetWithholdingTax: quotation.quotationSetWithholdingTax,
            note: quotation.note,
            selectedPackage: quotation.selectedPackage.id,
            customizePackageName: quotation.customizePakageName,
            seats: quotation.seats,
            packageDiscount: quotation.packageDiscount.map((discountData) => ({
               discount_cal_type: discountData.discount_cal_type,
               discount: discountData.discount
            })),
            addedAddons: quotation.addedAddons.map((addon) => ({
               code: addon.code,
               quantity: addon.quantity,
               price_per_unit: addon.price_per_unit,
               is_package_feature: !!addon.isPackageFeature,
               days: addon.days,
               addonDiscount: addon.addonDiscount.map((discountData) => ({
                  discount_cal_type: discountData.discount_cal_type,
                  discount: discountData.discount
               }))
            })),
            quotationSetDiscount: quotation.quotationSetDiscount.map((discountData) => ({
               discount_cal_type: discountData.discount_cal_type,
               discount: discountData.discount
            }))
         };
         try {
            const method = dataToSave.quotation_set_id ? 'put' : 'post';
            const urlToSave = `/order2/${order.id}/set`;
            const res = await axiosToken({
               method: method,
               url: urlToSave,
               data: dataToSave
            });
            onSuccess(res.data);
            dispatch(displayQuotationLoading(false));
            dispatch(getOrderById(order.id, true));
            dispatch(cleanUp());
         } catch (error) {
            dispatch(displayQuotationLoading(false));
            onError(error);
         }
      };

export const QUOTATION_LOADING = 'QUOTATION_LOADING';
export const BEGIN_QUOTATION = 'BEGIN_QUOTATION';
export const SET_SELECT_PACKAGE = 'SET_SELECT_PACKAGE';
export const SET_WITH_KEY = 'SET_WITH_KEY';
export const ADD_ADDON = 'ADD_ADDON';
export const REMOVE_ADDON = 'REMOVE_ADDON';
export const UPDATE_ADDED_ADDON = 'UPDATE_ADDED_ADDON';
export const ADD_PACKAGE_DISCOUNT = 'ADD_PACKAGE_DISCOUNT';
export const UPDATE_PACKAGE_DISCOUNT = 'UPDATE_PACKAGE_DISCOUNT';
export const REMOVE_PACKAGE_DISCOUNT = 'REMOVE_PACKAGE_DISCOUNT';
export const ADD_QUOTATION_SET_DISCOUNT = 'ADD_QUOTATION_SET_DISCOUNT';
export const UPDATE_QUOTATION_SET_DISCOUNT = 'UPDATE_QUOTATION_SET_DISCOUNT';
export const REMOVE_QUOTATION_SET_DISCOUNT = 'REMOVE_QUOTATION_SET_DISCOUNT';
export const SET_ERROR = 'SET_ERROR';
export const CLEAN_UP_QUOTATION_SET = 'CLEAN_UP_QUOTATION_SET';

export const displayQuotationLoading = (bool = true) => ({
   type: QUOTATION_LOADING,
   payload: { isLoading: bool }
});
export const begin = (quotationData) => ({
   type: BEGIN_QUOTATION,
   payload: { quotationData: quotationData }
});

export const setSelectPackage = (quotationData) => ({
   type: SET_SELECT_PACKAGE,
   payload: { quotationData: quotationData }
});
export const addPackageDiscount = (newDiscountData) => ({
   type: ADD_PACKAGE_DISCOUNT,
   payload: { data: newDiscountData }
});
export const updatePackageDiscount = (updatedDiscountData, index) => ({
   type: UPDATE_PACKAGE_DISCOUNT,
   payload: { data: updatedDiscountData, discountIndex: index }
});
export const removePackageDiscount = (id) => ({
   type: REMOVE_PACKAGE_DISCOUNT,
   payload: { id: id }
});
export const updateWithKey = (key, value) => ({
   type: SET_WITH_KEY,
   payload: { key, value }
});
export const addMoreAddon = (addonToAdd) => ({
   type: ADD_ADDON,
   payload: { addonToAdd }
});
export const removeAddon = (id) => ({
   type: REMOVE_ADDON,
   payload: { id }
});
export const updateAddedAddon = (updatedTargetAddon, index) => ({
   type: UPDATE_ADDED_ADDON,
   payload: { updatedTargetAddon, index }
});
export const addQuotationSetDiscount = (newDiscountData) => ({
   type: ADD_QUOTATION_SET_DISCOUNT,
   payload: { data: newDiscountData }
});
export const updateQuotationSetDiscount = (updatedDiscountData, index) => ({
   type: UPDATE_QUOTATION_SET_DISCOUNT,
   payload: { data: updatedDiscountData, discountIndex: index }
});
export const removeQuotatinSetDiscount = (id) => ({
   type: REMOVE_QUOTATION_SET_DISCOUNT,
   payload: { id: id }
});
export const setError = (error) => ({
   type: SET_ERROR,
   payload: { error: error }
});
export const cleanUp = () => ({
   type: CLEAN_UP_QUOTATION_SET
});
