import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Logo from 'src/components/logo/NormalLogo';

const useStyles = makeStyles()(() => ({
   root: {},
   toolbar: {
      height: 64
   }
}));

const TopBar = ({ className, ...rest }) => {
   const { classes, cx } = useStyles();

   return (
      <AppBar className={cx(classes.root, className)} elevation={0} {...rest}>
         <Toolbar className={classes.toolbar}>
            <RouterLink to="/">
               <Logo />
            </RouterLink>
         </Toolbar>
      </AppBar>
   );
};

TopBar.propTypes = {
   className: PropTypes.string
};

export default TopBar;
